<template>
  <v-card
    class="mx-auto mb-2 d-flex align-stretch flex-column"
    max-width="1200"
  >
    <v-row no-gutters align="center" justify="center" class="pt-1">
      <h3
        style="word-break: normal;text-align:center;"
        v-resize-text="{
          ratio: 1.5,
          minFontSize: '12px',
          maxFontSize: '16px',
          delay: 100,
        }"
      >
        {{ title.replace(/-/g, " ") }} :&nbsp;
      </h3>
      <h3
        style="word-break: normal"
        v-resize-text="{
          ratio: 1.5,
          minFontSize: '12px',
          maxFontSize: '16px',
          delay: 100,
        }"
      >
        Classement des résultats
      </h3>
    </v-row>
    <v-row class="justify-center mt-1 mb-1 pb-2 no-gutters">
      <!--  && electedButtonMajorite == 'blue' -->
      <v-btn
        :loading="loading"
        x-small
        v-model="electedCriteria.majorite"
        class="white--text"
        @click="check('majorite')"
        :color="electedButtonMajorite"
        text
      >
        Les majoritaires
        <!-- <v-icon right color="blue">mdi-medal</v-icon> -->
        <svg-icon
          right
          color="blue"
          type="mdi"
          :path="medalPath"
          size="16"
        ></svg-icon>
      </v-btn>

      <v-btn
        :loading="loading"
        x-small
        v-model="electedCriteria.pour"
        class="white--text mx-1"
        @click="check('pour')"
        :color="electedButtonPour"
        text
      >
        Le top des 'pour'
        <!-- <v-icon right color="orange">mdi-medal</v-icon> -->
        <svg-icon
          right
          color="orange"
          type="mdi"
          :path="medalPath"
          size="16"
        ></svg-icon>
      </v-btn>

      <v-btn
        :loading="loading"
        x-small
        v-model="electedCriteria.ecart"
        class="white--text"
        :color="electedButtonEcart"
        @click="check('ecart')"
        text
      >
        Les + fédérateurs
        <!-- <v-icon right color="purple">mdi-medal</v-icon> -->
        <svg-icon
          right
          color="purple"
          type="mdi"
          :path="medalPath"
          size="16"
        ></svg-icon>
      </v-btn>
    </v-row>
    <!-- <div class="overline ma-0">
          Classement des résultats :
          <v-tooltip bottom>
            <template v-slot:activator="">
            </template>
            <span>aide</span>
          </v-tooltip>
        </div> -->
    <!-- <v-btn icon v-bind="attrs" v-on="on">
                <v-icon color="grey lighten-1" size="x-small">mdi-help</v-icon>
              </v-btn> -->
  </v-card>
</template>

<script>
import Vuex from "vuex";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiMedal } from "@mdi/js";

export default {
  name: "VoteListHeader",
  components: {
    SvgIcon,
  },
  props: ["title", "infiniteLoading"],
  data: () => ({
    medalPath: mdiMedal,
    loading: false,
  }),
  computed: {
    ...Vuex.mapGetters("vote", [
      "electedCriteria",
      "electedPour",
      "electedEcart",
      "electedMajorite",
      "electedButtonEcart",
      "electedButtonPour",
      "electedButtonMajorite",
      "votesMajorite",
    ]),
    // loading: function () {
    //   return this.$store.state.loading;
    // },
  },
  //   mounted: async function () {
  //     // await this.$store.dispatch("loading", false);
  //     console.log("mounted");
  //   },
  //   beforeMount: function () {
  //     console.log("beforeMount");
  //   },
  //   unmounted: async function () {
  //     console.log("unmounted");
  //   },
  //   beforeUnmount: function () {
  //     console.log("beforeUnmount");
  //   },
  //   created: async function () {
  //     console.log("created");
  //   },
  //   beforeCreated: function () {
  //     console.log("beforeCreated");
  //   },
  //   updated: async function () {
  //     // if (this.$store.state.loading == false)
  //     //   await this.$store.dispatch("loading", true);
  //     console.log("up");
  //   },
  //   beforeUpdate: function () {
  //     console.log("beforeUp");
  //   },
  methods: {
    async check($event) {
      console.log($event);
      // await this.$store.dispatch("loading", true);
      await this.$store.dispatch("vote/electedCriteriaButtonActivated", $event)
      await this.$store.dispatch("vote/updateElectedCriteria", {
        electedEcart:
          this.$store.state.vote.electedCriteriaButtonActivated.ecart ==
          "purple"
            ? true
            : false,
        electedMajorite:
          this.$store.state.vote.electedCriteriaButtonActivated.majorite ==
          "blue"
            ? true
            : false,
        electedPour:
          this.$store.state.vote.electedCriteriaButtonActivated.pour == "orange"
            ? true
            : false,
      });
      // await this.$store.dispatch("loading", false);

      // await this.$store.dispatch("vote/updateElectedCriteria");
      await new Promise((resolve, reject) => {
        if (
          this.$store.state.route.name == "worldCountryThemeVoteList" &&
          (this.$store.state.vote.electedCriteria.pour ||
          this.$store.state.vote.electedCriteria.ecart ||
          this.$store.state.vote.electedCriteria.majorite)
        ) {        
          this.$router.push({
            name: "worldCountryThemeVoteListResult",
            params: this.$store.state.route.params,
          });
        } else if (
          this.$store.state.route.name == "worldCountryThemeVoteListResult" &&
          !this.$store.state.vote.electedCriteria.pour &&
          !this.$store.state.vote.electedCriteria.ecart &&
          !this.$store.state.vote.electedCriteria.majorite
        ) {
          // this.$router.go(-1)
          this.$router.push({
            name: "worldCountryThemeVoteList",
            params: this.$store.state.route.params,
          });
        } 
        resolve()
      });
      //   await this.$store.dispatch("loading", true);}

      //   await this.$store.dispatch(
      //     "vote/electedCriteriaButtonActivated",
      //     $event
      //   );
      //   await this.$store.dispatch("loading", false);
      //   this.loading = this.$store.state.loading;
      //   if (
      //     this.$store.state.vote.electedCriteriaButtonActivated.ecart ==
      //       "white" &&
      //     this.$store.state.vote.electedCriteriaButtonActivated.majorite ==
      //       "white" &&
      //     this.$store.state.vote.electedCriteriaButtonActivated.pour == "white"
      //   ) {
      //     this.loading = this.$store.state.loading;
      //   } 
      //   else {
      //     /*
      //             charger tous les votes si ce n'est pas déjà fait
      //           */
          // déterminer la taille de la liste si elle n'existe pas
          // console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeekkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk");

          // si tous les votes de la liste ne sont pas chargés, on le fait
          if (
            this.$store.state.list.worldCountryThemeVoteListLength ==
            this.$store.state.vote.votes.length
          ) {
            await this.$store.dispatch("vote/allVotesLoaded", true);
          }
          else await this.$store.dispatch("vote/allVotesLoaded", false);
          // else {
          //   // obligatoire
          //   if (this.infiniteLoading) this.infiniteLoading.status = 1;
          //   // commit le reste à charger dans querylimit qui se chargera dans le bindMore : totalvotelistlength - la liste affichée
          //   let queryLimit =
          //     this.$store.state.list.worldCountryThemeVoteListLength -
          //     this.$store.state.vote.votes.length;
          //   this.$store.commit(
          //     "vote/setQueryLimit",
          //     queryLimit <= 0 ? 201 : queryLimit
          //   );
          //   if (this.infiniteLoading) {
          //     await this.infiniteLoading.$emit(
          //       "infinite",
          //       this.infiniteLoading.stateChanger
          //     );
          //   }
          //   await this.$store.dispatch("vote/allVotesLoaded", true);
          // }
      //     // le tri est fait dans le bindMore
      //   }
      //   //   await this.$store.dispatch("loading", false);
      //   //    this.loading = this.$store.state.loading;
      // }
    },
  },
};
</script>
