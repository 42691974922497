var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto mb-2 d-flex align-stretch flex-column",attrs:{"max-width":"1200"}},[_c('v-row',{staticClass:"pt-1",attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('h3',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 1.5,
        minFontSize: '12px',
        maxFontSize: '16px',
        delay: 100,
      }),expression:"{\n        ratio: 1.5,\n        minFontSize: '12px',\n        maxFontSize: '16px',\n        delay: 100,\n      }"}],staticStyle:{"word-break":"normal","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.title.replace(/-/g, " "))+" :  ")]),_c('h3',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({
        ratio: 1.5,
        minFontSize: '12px',
        maxFontSize: '16px',
        delay: 100,
      }),expression:"{\n        ratio: 1.5,\n        minFontSize: '12px',\n        maxFontSize: '16px',\n        delay: 100,\n      }"}],staticStyle:{"word-break":"normal"}},[_vm._v(" Classement des résultats ")])]),_c('v-row',{staticClass:"justify-center mt-1 mb-1 pb-2 no-gutters"},[_c('v-btn',{staticClass:"white--text",attrs:{"loading":_vm.loading,"x-small":"","color":_vm.electedButtonMajorite,"text":""},on:{"click":function($event){return _vm.check('majorite')}},model:{value:(_vm.electedCriteria.majorite),callback:function ($$v) {_vm.$set(_vm.electedCriteria, "majorite", $$v)},expression:"electedCriteria.majorite"}},[_vm._v(" Les majoritaires "),_c('svg-icon',{attrs:{"right":"","color":"blue","type":"mdi","path":_vm.medalPath,"size":"16"}})],1),_c('v-btn',{staticClass:"white--text mx-1",attrs:{"loading":_vm.loading,"x-small":"","color":_vm.electedButtonPour,"text":""},on:{"click":function($event){return _vm.check('pour')}},model:{value:(_vm.electedCriteria.pour),callback:function ($$v) {_vm.$set(_vm.electedCriteria, "pour", $$v)},expression:"electedCriteria.pour"}},[_vm._v(" Le top des 'pour' "),_c('svg-icon',{attrs:{"right":"","color":"orange","type":"mdi","path":_vm.medalPath,"size":"16"}})],1),_c('v-btn',{staticClass:"white--text",attrs:{"loading":_vm.loading,"x-small":"","color":_vm.electedButtonEcart,"text":""},on:{"click":function($event){return _vm.check('ecart')}},model:{value:(_vm.electedCriteria.ecart),callback:function ($$v) {_vm.$set(_vm.electedCriteria, "ecart", $$v)},expression:"electedCriteria.ecart"}},[_vm._v(" Les + fédérateurs "),_c('svg-icon',{attrs:{"right":"","color":"purple","type":"mdi","path":_vm.medalPath,"size":"16"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }