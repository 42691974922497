<template>
  <v-container fluid class="pt-0">
    <VoteListHeader :title="themez" :infiniteLoading="$refs.infiniteLoading" />
    <v-card
      max-width="1200"
      class="mx-auto mb-2 d-flex align-stretch flex-column"
      v-for="(vote, index) in votes"
      :key="vote.id"
      loading="false"
    >
      <v-row no-gutters align="start" justify="start" class="pt-1">
        <v-col
          cols="1"
          class="d-flex flex-column caption font-weight-light justify-end"
        >
          <VoteActionLeftCard :vote="vote" :index="index" />
        </v-col>

        <v-col cols="11" class="pl-1 mt-1">
          <v-card-title primary-title class="pa-0 subtitle-2 mb-auto">
            <v-col cols="12" class="pl-1">
              <v-row>
                <v-col cols="9" class="pt-0" style="padding-right: 0px">
                  <h3
                    style="
                      word-break: normal;
                      line-height: 1.2;
                      padding-top: 8px;
                    "
                    v-resize-text="{
                      ratio: 1.5,
                      minFontSize: '12px',
                      maxFontSize: '16px',
                      delay: 100,
                    }"
                  >
                    {{ vote.name | capitalize }}
                  </h3>
                </v-col>
                <v-col class="pt-0">
                  <VoteMedal :medals="vote.medal" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pt-0" style="padding-right: 18px">
                  <h6
                    style="word-break: break-word; line-height: 1.2"
                    v-resize-text="{
                      ratio: 1.5,
                      minFontSize: '8px',
                      maxFontSize: '10px',
                      delay: 100,
                    }"
                  >
                    {{ vote.description | capitalize }}
                  </h6>
                </v-col>
              </v-row>
              <VoteLineBarChoiceResult :vote="vote" choice="one" color="blue" />
              <VoteLineBarChoiceResult :vote="vote" choice="two" color="red" />
            </v-col>
          </v-card-title>
          <VoteListCategory
            :voteCategory="vote.category"
            :voteSource="vote.url"
          />
        </v-col>
      </v-row>
      <v-row align="center" justify="end" class="mt-0 pr-2">
        <v-card-actions class="justify-end mb-2 mr-2 pt-0">
          <v-spacer></v-spacer>
          <v-btn icon>
            <!-- <v-icon>mdi-chart-multiline</v-icon> -->
            <svg-icon
              type="mdi"
              :path="chartMultilinePath"
              size="16"
            ></svg-icon>
          </v-btn>

          <v-btn @click="toVote(vote)" color="success" fab x-small>
            <!-- <v-icon>mdi-vote</v-icon> -->
            <svg-icon type="mdi" :path="votePath" size="16"></svg-icon>
          </v-btn>
        </v-card-actions>
      </v-row>
    </v-card>

    <infinite-loading
      @infinite="infiniteHandler"
      :key="$route.name"
      :identifier="$route.name"
      ref="infiniteLoading"
    >
      <span slot="no-more"></span>
      <span slot="no-results"></span>
    </infinite-loading>
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      x-small
      bottom
      right
      color="black"
      @click="toTop"
    >
      <!-- <v-icon>mdi-chevron-up</v-icon> -->
      <svg-icon type="mdi" :path="chevronUpPath" size="16"></svg-icon>
    </v-btn>
    <v-btn
      v-show="
        displayAllVotesButtonVisible &&
        !$store.state.vote.displayAllVotes &&
        $store.state.vote.allVotesLoaded &&
        $store.state.route.name == 'worldCountryThemeVoteList'
      "
      @click="displayAllVotes()"
      >voir le reste
    </v-btn>
  </v-container>
</template>

<script>
import Vuex from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import VoteLineBarChoiceResult from "@/component/vote/ui/VoteLineBarChoiceResult.vue";
import VoteListCategory from "@/component/vote/voteItem/ui/VoteListCategory.vue";
import VoteListHeader from "@/component/vote/voteList/VoteListHeader.vue";
import VoteMedal from "@/component/vote/voteItem/ui/VoteMedal.vue";
import VoteActionLeftCard from "@/component/user/ui/VoteActionLeftCard.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiChartMultiline, mdiVote, mdiChevronUp, mdiMedal } from "@mdi/js";

export default {
  components: {
    InfiniteLoading,
    VoteActionLeftCard,
    VoteLineBarChoiceResult,
    VoteListCategory,
    VoteListHeader,
    VoteMedal,
    SvgIcon,
  },
  filters: {
    truncate: function (text, length, suffix) {
      return text.substring(0, length) + suffix;
    },
  },
  name: "vote-list",
  data: () => ({
    fab: false,
    themeName: "",
    chevronUpPath: mdiChevronUp,
    votePath: mdiVote,
    displayAllVotesButtonVisible: false,
    chartMultilinePath: mdiChartMultiline,
    medalPath: mdiMedal,
  }),
  methods: {
    displayAllVotes() {
      this.$store.commit("vote/displayAllVotes", true);
      this.$store.getters["vote/votes"];
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    infiniteHandler($state) {
      // se charge que quand on tape l'url des résultats directement

      if (this.$store.state.route.name == "worldCountryThemeVoteListResult") {
        let queryLimit =
          this.$store.state.list.worldCountryThemeVoteListLength -
          this.$store.state.vote.votes.length;
        this.$store.commit(
          "vote/setQueryLimit",
          queryLimit <= 0 ? 201 : queryLimit
        );
        this.$store.dispatch("vote/bindAll", {
          loading: $state,
          theme: this.$store.state.route,
          queryLimit: this.$store.state.vote.queryLimit,
        });
        this.$store.dispatch("vote/allVotesLoaded", true);
      } else {
        this.$store.dispatch("vote/bind", {
          loading: $state,
          theme: this.$store.state.route,
          queryLimit: this.$store.state.vote.queryLimit,
        });
      }
    },
    toVote(vote) {
      let voteParam = { commitz: "", routez: "" };
      if (this.$store.state.route.name == "worldThemeVoteList")
        voteParam = {
          commitz: "list/setWorldThemeVoteName",
          routez: "worldThemeVote",
        };
      if (this.$store.state.route.name == "worldCountryZoneThemeVoteList")
        voteParam = {
          commitz: "list/setWorldCountryZoneThemeVoteName",
          routez: "worldCountryZoneThemeVote",
        };
      if (this.$store.state.route.name == "worldCountryThemeVoteList") {
        voteParam = {
          commitz: "list/setWorldCountryThemeVoteName",
          routez: "worldCountryThemeVote",
        };
        this.$store.commit(
          "list/setWorldCountryThemeVoteListLength",
          vote.voteListLength
        );
      }
      if (this.$store.state.route.name == "worldCountryThemeVoteListResult") {
        voteParam = {
          commitz: "list/setWorldCountryThemeVoteName",
          routez: "worldCountryThemeVote",
        };
        this.$store.commit(
          "list/setWorldCountryThemeVoteListLength",
          vote.voteListLength
        );
      }
      if (this.$store.state.route.name == "worldZoneThemeVoteList")
        voteParam = {
          commitz: "list/setWorldZoneThemeVoteName",
          routez: "worldZoneThemeVote",
        };

      this.$store.commit(voteParam.commitz, vote.name);
      this.$store.dispatch("vote/selectVote", vote);
      let paramsForRoute = this.paramsForRoute;
      paramsForRoute.id = vote.id;
      this.$router.push({
        name: voteParam.routez,
        params: paramsForRoute,
      });
    },
  },
  mounted: function () {
    setTimeout(() => {
      this.displayAllVotesButtonVisible = true;
    }, 3000);

    this.$store.getters.breadcrumb;
    // hack pour maj list
    if (
      this.$store.state.route.from.name == "worldCountryThemeVote" ||
      this.$store.state.route.from.name == "worldThemeVote" ||
      this.$store.state.route.from.name == "worldZoneThemeVote" ||
      this.$store.state.route.from.name == "worldCountryZoneThemeVote"
    ) {
    } else {
      this.$store.commit("vote/feedVotes", []);
    }
    if (
      this.$store.state.list.worldCountryThemeVoteListLength == 0 ||
      this.$store.state.list.worldCountryThemeVoteListLength === undefined
    )
      this.$store.dispatch("list/getWorldCountryThemeVoteListLength");
  },
  computed: {
    votes: function () {
      return this.$store.getters["vote/votes"];
    },
    ...Vuex.mapState("list", [
      "worldCountryZoneThemeName",
      "worldCountryZone",
      "worldCountryZoneTheme",
      "worldZoneTheme",
    ]),
    ...Vuex.mapGetters("list", ["paramsForRoute"]),
    themez: function () {
      if (this.$store.state.route.name == "worldThemeVoteList")
        return this.$store.state.list.worldThemeName;
      if (
        this.$store.state.route.name == "worldCountryThemeVoteList" ||
        this.$store.state.route.name == "worldCountryThemeVoteListResult"
      )
        return this.$store.state.list.worldCountryThemeName;
      if (this.$store.state.route.name == "worldCountryZoneThemeVoteList")
        return this.$store.state.list.worldCountryZoneThemeName;
      if (this.$store.state.route.name == "worldZoneThemeVoteList")
        return this.$store.state.list.worldZoneThemeName;
    },
  },
  // destroyed: function () {
  // },
};
</script>
